import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Products from "./pages/Products";
import Usecase from "./pages/Usecase";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    // <Router basename='/Sayana_AI'>
    <Router>
      <Toaster position="top-center" reverseOrder={false}  toastStyle={{ zIndex: 1100 }} />
      <div className="App">
        <Navbar />
        <div className="main_conatiner">
          <Home />
          <Products />
          <Usecase />
          <Contact />
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
