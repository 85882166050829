import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Car from "../assets/videos/Registration_Number_Recognition.mp4";
import Face from "../assets/videos/Facial_Analysis.mp4";
import People from "../assets/videos/People_Counting.mp4";
import DataExt from "../assets/videos/Data_Extraction.mp4";

const UsecaseSlider = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div>
      <Carousel
        responsive={responsive}
        customTransition="transform 0.7s ease"
        transitionDuration={500}
      >
        <div className="slide">
          <video src={Car} autoPlay muted loop className="slide_video"></video>
          <div className="slide_content">
            <p className="slide_text">
              Instantly decode the road: Capture and understand vehicle
              registration numbers in a flash
            </p>
          </div>
        </div>

        <div className="slide">
          <video src={Face} autoPlay muted loop className="slide_video"></video>
          <div className="slide_content2">
            <p className="slide_text">
              Delve into the world of human behaviour & understanding with
              Facial Analysis solution using computer vision
            </p>
          </div>
        </div>

        <div className="slide">
          <video
            src={People}
            autoPlay
            muted
            loop
            className="slide_video"
          ></video>
          <div className="slide_content">
            <p className="slide_text">
              Automatically track and count people entering, exiting, or within
              a designated area
            </p>
          </div>
        </div>
        {/* <div className="slide">
          <video
            src={DataExt}
            autoPlay
            muted
            loop
            className="slide_video"
          ></video>
          <div className="slide_content2">
            <p className="slide_text">
              Automatically track and count people entering, exiting, or within
              a designated area
            </p>
          </div>
        </div> */}
      </Carousel>
    </div>
  );
};

export default UsecaseSlider;
