import React from "react";
import OCR from "../assets/images/OCR.webp";
import Face from "../assets/images/Face.webp";
import ODC from "../assets/images/ODC.webp";
import OCRV from "../assets/videos/Sayana_OCR.mp4";
import ODCV from "../assets/videos/Sayana_ODC.mp4";
import ExpV from "../assets/videos/Sayana_Expression.mp4";
import ConnectV from "../assets/videos/Sayana_Connect.mp4";
import "../assets/css/products.css";

const Products = () => {
  return (
    <div className="section" id="product">
      <div className="product_container f-container">
        <div className="product_overview">
          <div className="overview_text f-container">
            <h1 className="heading">Sayana OCR & IDP</h1>
            <p className="text">
              Effortlessly streamline document workflows with advanced AI,
              transforming how you process and extract valuable insights from
              your documents
            </p>
            <button className="btn">
              <a href="#contact" className="btn_link">
                Get Started
              </a>
            </button>
          </div>

          <div className="overview_video">
            <video
              src={OCRV}
              autoPlay
              muted
              loop
              className="product_video"
            ></video>
          </div>
        </div>

        <div className="product_overview">
          <div className="overview_video">
            <video
              src={ODCV}
              autoPlay
              muted
              loop
              className="product_video"
            ></video>
          </div>

          <div className="overview_text f-container">
            <h1 className="heading">Counting Objects with Computer Vision</h1>
            <p className="text">
              Experience seamless object counting using cutting-edge computer
              vision technology from image and video
            </p>
            <button className="btn">
              <a href="#contact" className="btn_link">
                Get Started
              </a>
            </button>
          </div>
        </div>

        <div className="product_overview">
          <div className="overview_text f-container">
            <h1 className="heading">Sayana Expression</h1>
            <p className="text">
              Interprets human face emotions from images and videos using AI
            </p>
            <button className="btn">
              <a href="#contact" className="btn_link">
                Get Started
              </a>
            </button>
          </div>

          <div className="overview_video">
            <video
              src={ExpV}
              autoPlay
              muted
              loop
              className="product_video"
            ></video>
          </div>
        </div>

        <div className="product_overview">
          <div className="overview_video">
            <video
              src={ConnectV}
              autoPlay
              muted
              loop
              className="product_video"
            ></video>
          </div>

          <div className="overview_text f-container">
            <h1 className="heading">Sayana Connect</h1>
            <p className="text">
              Replicate human conversations and enhance the customer experience
            </p>
            <button className="btn">
              <a href="#contact" className="btn_link">
                Get Started
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
