import React from "react";
import "../assets/css/usecase.css";
import Carousel from "../components/UsecaseSlider";

const Usecase = () => {
  
  return (
    <div className="section usecase_section">
      <h1 className="heading">
        Explore Our AI and Computer <br /> Vision Use-cases
      </h1>
      <div className="slider_container">
        <Carousel />
      </div>
      
    </div>
  );
};

export default Usecase;
