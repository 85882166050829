import React from "react";
import Logo from '../assets/images/Logo.svg'
import Tel from '../assets/images/Telephone.svg';
import Mail from '../assets/images/mail.svg';
import "../assets/css/footer.css"

const Footer = () => {
  return (
    <div className="section footer_section">
        <div className="footer">

      <img src={Logo} alt="" className="company_logo" />
      <div className="location_container">
        <h3 className="location_heading">Our locations</h3>
        {/* <p className="location_addr">
          Tokyo, Koto-Ku, Ariake, 3-7-26, Ariake Frontier Building Tower B,
          Level 9
        </p> */}
        <p className="location_addr">
        34-B, Banskho House, Govind Nagar East Amer Road, Jaipur, Rajasthan
        </p>
      </div>

      <div className="contact_container">
        <h3 className="contact_heading">Contact Us</h3>
        <p className="contact_detail">
          <img src={Tel} alt="" />
          <p className="contact_num">
          +91890567284
          </p>
        </p>
        <p className="contact_detail">
          <img src={Mail} alt="" />
          <p className="contact_num">
          sayana.work@sayana-ai.com
          </p>
        </p>
      </div>

      <div className="getStarted f-container">
        <h1>Get started <br /> with Sayana AI</h1>
        <a href="#contact" className="btn_link">

          <button className="btn">Get Started</button>
        </a>
      </div>
        </div>

        <hr />

      <p className="copyright_text">
      Copyright © 2024 Sayana AI. All rights Reserved.. 
      </p>
    </div>
  );
};

export default Footer;
