import React from "react";

const Home = () => {
  return (
    <div className="section" id="home">
      <div className="f-container home_container">
        <h1 className="hero_heading">
        See the Future with Our <br /> Computer Vision Excellence
        </h1>
        <p className="text">
          Developing, Empowering your Business with Sayana-AI Computer <br />{" "}
          Vision Products and Solutions
        </p>
        <button className="btn">
          {" "}
          <a className="btn_link" href="#contact">
            Get Started
          </a>
        </button>
      </div>
    </div>
  );
};

export default Home;
