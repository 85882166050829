import React, { useEffect, useState } from 'react';
import Logo from '../assets/images/Logo.svg';
import '../assets/css/navbar.css';


const Navbar = () => {
  const [isNavOpen, setNavOpen] = useState(false);

  const handleLinkClick = () => {
    if (window.innerWidth <= 768) {
      setNavOpen(false);
    }
  };

  const handleNavToggle = () => {
    setNavOpen(!isNavOpen);
  };

  // Close the navbar when the window is resized
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setNavOpen(false);
      }
    };


    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <div className="navbar">
      <nav className="navbar_nav">
        <a href='#home' className="" onClick={handleLinkClick}>
          <img className="nav_logo" src={Logo} alt="" />
        </a>
        <div className={`links_container ${isNavOpen ? "open" : ""}`}>
          <div
            className={`menu-icon ${isNavOpen ? "open" : ""}`}
            onClick={handleNavToggle}
          >
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
          <ul className={`nav_navlist ${isNavOpen ? "show" : ""}`}>
            <li className="nav_links" onClick={handleLinkClick}>
           <a href="#home" className="link">
            Home
           </a>
            </li>

            <li className="nav_links" onClick={handleLinkClick}>
              <a href="#product" className="link">
                Products
              </a>
            </li>

            <li
              className="nav_links"
              onClick={handleLinkClick}
            >
              <a href="#contact" className="link">Contact Us</a>
            </li>
            
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;

