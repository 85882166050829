import React, { useState } from "react";
import "../assets/css/contact.css";
import toast from 'react-hot-toast';


const Contact = () => {
  const [showContactForm, setShowContactForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const [focusedInput, setFocusedInput] = useState(null);
  const [validationErrors, setValidationErrors] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Clear validation error when user starts typing
    if (validationErrors[name]) {
      setValidationErrors({
        ...validationErrors,
        [name]: "",
      });
    }
  };

  const handleInputFocus = (fieldName) => {
    setFocusedInput(fieldName);
  };

  const handleInputBlur = () => {
    setFocusedInput(null);
  };

  const handleContactButtonClick = () => {
    setShowContactForm(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // RegEx for email
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Validate the email
    const emailError = !emailRegex.test(formData.email);

    // Validate the phone number
    const phoneNumberError = !/^\d+$/.test(formData.phone);

    // Validate the form
    const newValidationErrors = {};

    if (formData.name.trim() === "") {
      newValidationErrors.name = "Name is required*";
    }

    if (formData.email.trim() === "") {
      newValidationErrors.email = "Email is required*";
    } else if (emailError) {
      newValidationErrors.email = "Enter a valid email address";
    }

    if (formData.phoneNumber.trim() === "") {
      newValidationErrors.phoneNumber = "Phone Number is required*";
    } else if (formData.phoneNumber.length < 10) {
      newValidationErrors.phoneNumber = "Please enter 10 digit number";
    }

    if (formData.message.trim() === "") {
      newValidationErrors.message = "Message is required*";
    }

    setValidationErrors(newValidationErrors);

    // Check if there are validation errors
    if (Object.keys(newValidationErrors).length === 0) {
      setIsLoading(true);
      let payload = {
        name: formData.name,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        message: formData.message,
      };
      try {
        const response = await fetch(
          "https://us-central1-ocrappbackend.cloudfunctions.net/chargerDhundo/api/sayana/sendMail",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );

        setIsLoading(false);
        if (response.status === 200) {
          toast.success("Thanks for contacting us..!")
          setFormData({
            name: "",
            email: "",
            phoneNumber: "",
            message: "",
          });
          setShowContactForm(false);
        } else {
          setFormData({
            name: "",
            email: "",
            phoneNumber: "",
            message: "",
          });
        }
      } catch (error) {
        setIsLoading(false);
        toast.error("Please try again later!")
        setFormData({
          name: "",
          email: "",
          phoneNumber: "",
          message: "",
        });
      }
    }
  };
  return (
    <div id="contact" className="section f-container help_section">
      {!showContactForm && (
        <>
          <h1 className="heading">
            Like what you see? Start your journey with Sayana AI
          </h1>
          <button onClick={handleContactButtonClick} className="btn">
            Contact Us
          </button>
        </>
      )}

      {showContactForm && (
        <div className="contact_form">
          <h1 className="heading">
            Like what you see? Start your journey with Sayana AI
          </h1>
          <div className="form_container">
            <div
              className={`input_container ${
                focusedInput === "name" ? "focused" : ""
              } ${validationErrors.name ? "error" : ""}`}
            >
              <label
                className={`input_lable ${
                  validationErrors.name ? "required" : ""
                }`}
              >
                {validationErrors.name ? validationErrors.name : "Name*"}
              </label>

              <input
                type="text"
                name="name"
                class="input"
                required=""
                value={formData.name}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                onFocus={() => handleInputFocus("name")}
              />
            </div>

            <div
              className={`input_container ${
                focusedInput === "email" ? "focused" : ""
              } ${validationErrors.email ? "error" : ""}`}
            >
              <label
                className={`input_lable ${
                  validationErrors.email ? "required" : ""
                }`}
              >
                {validationErrors.email ? validationErrors.email : "Email*"}
              </label>
              <input
                type="text"
                name="email"
                class="input"
                required=""
                value={formData.email}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                onFocus={() => handleInputFocus("email")}
              />
            </div>

            <div
              className={`input_container ${
                focusedInput === "phoneNumber" ? "focused" : ""
              } ${validationErrors.phoneNumber ? "error" : ""}`}
            >
              <label
                className={`input_lable ${
                  validationErrors.phoneNumber ? "required" : ""
                }`}
              >
                {validationErrors.phoneNumber
                  ? validationErrors.phoneNumber
                  : "Phone Number*"}
              </label>
              <input
                type="num"
                name="phoneNumber"
                class="input"
                required=""
                value={formData.phoneNumber}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                onFocus={() => handleInputFocus("phoneNumber")}
              />
            </div>

            <div
              className={`input_container ${
                focusedInput === "message" ? "focused" : ""
              } ${validationErrors.message ? "error" : ""}`}
            >
              <label
                className={`input_lable ${
                  validationErrors.message ? "required" : ""
                }`}
              >
                {validationErrors.message
                  ? validationErrors.message
                  : "Message"}
              </label>

              <textarea
                name="message"
                className="textarea"
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                onFocus={() => handleInputFocus("message")}
                value={formData.message}
              ></textarea>
            </div>

            <div className="container">
              <button onClick={handleSubmit} className="btn submit_btn">
                Submit
                {isLoading &&(
                  <div class="dot-spinner">
                  <div class="dot-spinner__dot"></div>
                  <div class="dot-spinner__dot"></div>
                  <div class="dot-spinner__dot"></div>
                  <div class="dot-spinner__dot"></div>
                  <div class="dot-spinner__dot"></div>
                  <div class="dot-spinner__dot"></div>
                  <div class="dot-spinner__dot"></div>
                  <div class="dot-spinner__dot"></div>
              </div>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contact;
